$(document).on('turbolinks:load', function() {
  let links = document.querySelectorAll('.js-open-window');

  links.forEach(link => {
    link.addEventListener('click', function() {
      let w = link.dataset.windowWidth;
      let h = link.dataset.windowHeight;
      let l = Math.max(screen.width / 2 - w / 2, 0);
      let t = Math.max(screen.height / 2 - h / 2, 0);
      window.open(link.dataset.url, link.dataset.windowTitle,
                  'personalbar=0, toolbar=0, location=0, directories=0, status=0, menubar=0, ' +
                  `scrollbars=1, resizable=1, copyhistory=0, width=${w}, height=${h}, top=${t}, left=${l}`);
    });
  });
});
