// see https://stackoverflow.com/questions/31402576/enable-focus-only-on-keyboard-use-or-tab-press#answer-51093815
$(document).on('turbolinks:load', function() {
  document.body.addEventListener('mousedown', function() {
    document.body.classList.add('using-mouse');
  });
  document.body.addEventListener('keydown', function(e) {
    if (e.keyCode === 9) {
      document.body.classList.remove('using-mouse');
    }
  });
});
