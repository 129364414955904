$(document).on('turbolinks:load', function() {
  let checkoutRedirect = document.querySelector('.checkout-redirect');
  if (checkoutRedirect) {
    setTimeout(function() {
      let stripe = Stripe(checkoutRedirect.dataset.stripePublicApiKey);
      let sessionId = checkoutRedirect.dataset.stripeSessionId;

      stripe.redirectToCheckout({ sessionId: sessionId })
        .then(function(result) {
          if (result.error) {
            alert(result.error.message);
          }
        });
    }, 3000);
  }
});
