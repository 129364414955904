$(document).on('turbolinks:load', function() {
  $('.js-subscription-trial-popup').each(function() {
    if (!$(this).data('user-has-subscription') && !document.cookie.match(/subscription_trial_popup_shown=1/)) {
      setTimeout(function() {
        if (!window.location.href.includes('/signup/new')) {
          $('.js-subscription-trial-popup').modal('show');
          document.cookie = `subscription_trial_popup_shown=1; max-age=${60 * 60 * 24 * 365}; path=/`;
        }
      }, 7000);
    }
  });
});
