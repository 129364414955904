$(document).on('turbolinks:load', function() {
  let viewMoreButtonEl = document.querySelector('.js-view-more');

  if (viewMoreButtonEl) {
    viewMoreButtonEl.addEventListener('ajax:success', event => {
      const [data, _status, _xhr] = event.detail;

      if (!data.show_more) {
        viewMoreButtonEl.closest('.js-view-more-wrap').classList.add('d-none');
      }

      let deduplicatedClassrooms = removeDuplications(data.items_html);
      document.querySelector(viewMoreButtonEl.dataset.viewMoreTarget).insertAdjacentHTML('beforeend', deduplicatedClassrooms);

      updateCurrentPage();
    });
    viewMoreButtonEl.addEventListener('ajax:error', event => {
      console.log('===>', 'ERROR!');
      console.log(event.detail);
    });

    let updateCurrentPage = function() {
      let viewMoreSearchParams = new URL(viewMoreButtonEl.href).searchParams;
      viewMoreSearchParams.set('page', parseInt(viewMoreSearchParams.get('page')) + 1);
      viewMoreButtonEl.search = viewMoreSearchParams.toString()
    };

    let removeDuplications = function(classroomsHtml) {
      let oldClassroomIds = [];

      document.querySelector('#search-results').querySelectorAll('.classroom-card').forEach(el => {
        oldClassroomIds.push(parseInt(el.dataset.id))
      });

      parser = new DOMParser();
      newClassroomsDoc = parser.parseFromString(classroomsHtml, 'text/html');
      newClassroomsDoc.querySelectorAll('.classroom-card').forEach(el => {
        if (oldClassroomIds.includes(parseInt(el.dataset.id))) {
          el.parentNode.remove();
        }
      });

      return newClassroomsDoc.body.innerHTML;
    };
  }

});
