$(document).on('turbolinks:load', function() {
  if (document.querySelector('.hbspt-form')) {
    setTimeout(function() {
      let formEl = document.querySelector('.hbspt-form');
      let emailInput = formEl.querySelector('input[type=email]');
      let submitInput = formEl.querySelector('input[type=submit]');

      emailInput.toggleAttribute('disabled', true);
      submitInput.toggleAttribute('disabled', true);

      formEl.querySelector('input[type=checkbox].hs-input').addEventListener('change', function() {
        emailInput.toggleAttribute('disabled', !this.checked);
        submitInput.toggleAttribute('disabled', !this.checked);
      });

    }, 500);

    setInterval(function () {
      let submittedMessage = document.querySelector('.submitted-message');
      if (submittedMessage) {
        if (!document.querySelector('.hbspt-form .checkmark-sign')) {
          let div = document.createElement('div');
          div.classList.add('checkmark-sign');
          submittedMessage.parentElement.prepend(div);
        }
      }
    }, 1);
  }
});
