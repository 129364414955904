import Rails from '@rails/ujs';

$(document).on('turbolinks:load', function() {
  document.querySelectorAll('.js-muse-player__play').forEach(playEl => {
    let playerEl = playEl.closest('.js-muse-player');
    let containerEl = playerEl.querySelector('.js-muse-player__container');
    let videoId = playerEl.dataset.sourceId;

    playEl.addEventListener('click', function() {
      if (videoId) {
        let musePlayer = window.MusePlayer({
          container: containerEl,
          video: videoId,
          links: false,
          logo: false,
          autoplay: true,
        });
        musePlayer.on('metadata', metadata => {
          _paq.push(['MediaAnalytics::setMediaTitleFallback', function() {
            return metadata.title;
          }]);
          // this fixes the error in scanForMedia call
          musePlayer.container.getElementById = function(name) {
            return this.querySelector('#' + name);
          }
          _paq.push(['MediaAnalytics::scanForMedia', musePlayer.container]);

          fetch(playerEl.dataset.videoTrackClickUrl, {
            method: 'POST',
            headers: { 'X-CSRF-Token': Rails.csrfToken() },
          });
        });
        playerEl.classList.add('muse-player--loaded');
      } else {
        let paymentOptions = document.querySelector('.js-muse-player-payment-options');
        if (paymentOptions) {
          paymentOptions.scrollIntoView({ behavior: 'smooth' });
        }
      }
    });

  });
});
