/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <_= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <_= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('jquery');
import 'bootstrap/js/src/index';

// docs: http://davidstutz.github.io/bootstrap-multiselect/
import 'bootstrap-multiselect';

import svg4everybody from 'svg4everybody';
svg4everybody();
import 'element-closest-polyfill';
import 'whatwg-fetch';

import 'request-animation-frame-polyfill'; // needed for smoothscroll
import smoothscroll from 'smoothscroll-polyfill'; // needed for smooth scrollIntoView
smoothscroll.polyfill();

import 'application/classrooms/show';
import 'application/checkout';
import 'application/clamp';
import 'application/category-multiselect';
import 'application/year-groups-multiselect';
import 'application/subjects-multiselect';
import 'application/using-mouse';
import 'application/multi-slider';
import 'application/bootstrap-tooltip';
import 'application/show-more-classes';
import 'application/subscription-trial-popup';
import 'application/open-window';
import 'application/muse-player';
import 'application/white-header-logic';
import 'application/home-signup-form';
import 'application/view-more';
import 'application/recaptcha';
