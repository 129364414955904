$(document).on('turbolinks:load', function() {
  $('.js-show-more-classes').click(function () {
    let $target = $(this.dataset.target);
    let show = $target.hasClass('d-none');

    if ($target.is(':empty')) {
      let cardsHtml = $target.closest('.container').find('.multi-slider__container').html();
      $target.html(cardsHtml);
    }

    $target.toggleClass('d-none', !show);
    $(this).closest('.container').find('.multi-slider').toggleClass('d-none', show);

    if (!this.initialText) {
      this.initialText = this.textContent;
    }
    this.textContent = show ? this.dataset.hideText : this.initialText;

    if (show) {
      window.scrollTo({ top: $(this).closest('.container')[0].offsetTop, behavior: 'smooth' });
    }
  });
});
