class HeaderColorSwitcher {
  constructor(navbarEl) {
    this.navbarEl = navbarEl;
    this.logoImg = navbarEl.querySelector('#logo-img');
    this.handler = this.switchToWhiteModeUnlessTop.bind(this);

    window.addEventListener('scroll', this.handler);
    this.switchToWhiteModeUnlessTop();
  }

  switchToWhiteModeUnlessTop() {
    let shouldBeWhite = window.scrollY > 0;
    if (shouldBeWhite !== this.shouldBeWhiteWas) {
      this.navbarEl.classList.toggle('main-header--white', shouldBeWhite);
      this.logoImg.src = shouldBeWhite ? this.logoImg.dataset.logoDark : this.logoImg.dataset.logoLight;
      this.shouldBeWhiteWas = shouldBeWhite;
    }
  }

  cleanup() {
    window.removeEventListener('scroll', this.handler);
  }
}

$(document).on('turbolinks:load', function() {
  let navbarEl = document.querySelector('.js-white-header-logic');
  if (navbarEl && navbarEl.classList.contains('main-header--promo-page')) {
    window.headerColorSwitcher = new HeaderColorSwitcher(navbarEl);
  }
}).on('turbolinks:before-render', function() {
  if (window.headerColorSwitcher) {
    window.headerColorSwitcher.cleanup();
  }
});
