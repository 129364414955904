$(document).on('turbolinks:load', function() {
  $('.js-category-multiselect').each(function() {
    if ($(this).siblings('.multiselect-wrap').length) {
      return; // fix for turbolinks
    }
    $(this).multiselect({
      allSelectedText: 'All categories',
      nonSelectedText: 'All categories',
      buttonContainer: '<div class="multiselect-wrap"/>',
      buttonClass: $(this).data('button-class'),
    });
  });
});
