$(document).on('turbolinks:load', function() {
  $('.js-year-groups-multiselect').each(function() {
    if ($(this).siblings('.multiselect-wrap').length) {
      return; // fix for turbolinks
    }
    $(this).multiselect({
      allSelectedText: 'All year groups',
      nonSelectedText: 'All year groups',
      numberDisplayed: 3,
      buttonContainer: '<div class="multiselect-wrap"/>',
      buttonClass: $(this).data('button-class'),
    });
  });
});
