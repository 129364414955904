import clamp from 'clamp';

$(document).on('turbolinks:load', function() {
  $('.multi-slider').each(function() {
    let sliderEl = this;
    sliderEl.sliderContainer = sliderEl.querySelector('.multi-slider__container');
    sliderEl.frame = sliderEl.querySelector('.multi-slider__frame');
    sliderEl.prevControl = sliderEl.querySelector('.multi-slider__control--prev');
    sliderEl.nextControl = sliderEl.querySelector('.multi-slider__control--next');
    sliderEl.curSlideI = 0;

    sliderEl.refreshSlidesInfo = function() {
      let firstSlide = sliderEl.sliderContainer.firstElementChild;
      let slideWidth = firstSlide.offsetWidth;
      let frameWidth = sliderEl.frame.offsetWidth;
      let slidesCount = sliderEl.sliderContainer.childElementCount;
      sliderEl.slidesPerFrame = Math.round(frameWidth / slideWidth);
      sliderEl.lastFrameSlideI = Math.floor((slidesCount - 1) / sliderEl.slidesPerFrame) * sliderEl.slidesPerFrame;
    };

    sliderEl.updateFrameView = function() {
      sliderEl.frame.scrollLeft = sliderEl.sliderContainer.children[sliderEl.curSlideI].offsetLeft;
    };

    sliderEl.updateArrowsDisabled = function() {
      sliderEl.prevControl.classList.toggle('is-disabled', sliderEl.curSlideI <= 0);
      sliderEl.nextControl.classList.toggle('is-disabled', sliderEl.curSlideI >= sliderEl.lastFrameSlideI);
    };

    sliderEl.addEventListener('click', function(e) {
      if (e.target !== this.prevControl && e.target !== this.nextControl) {
        return;
      }
      let isLeft = e.target === this.prevControl;
      let sign = isLeft ? -1 : 1;
      sliderEl.refreshSlidesInfo();
      sliderEl.curSlideI = clamp(sliderEl.curSlideI + sign * sliderEl.slidesPerFrame, 0, sliderEl.lastFrameSlideI);

      sliderEl.updateFrameView();
      sliderEl.updateArrowsDisabled();
    });

    sliderEl.refreshSlidesInfo();
    sliderEl.updateArrowsDisabled();
  });

  function multiSlidersOnResize() {
    let sliders = document.querySelectorAll('.multi-slider');
    for (let i = 0; i < sliders.length; ++i) {
      let slider = sliders[i];
      slider.updateFrameView();
      slider.refreshSlidesInfo();
      slider.updateArrowsDisabled();
    }
  }

  if (document.querySelector('.multi-slider')) {
    window.addEventListener('resize', multiSlidersOnResize);
  } else {
    window.removeEventListener('resize', multiSlidersOnResize);
  }
});
