$(document).on('turbolinks:load', function() {
  $('.js-subjects-multiselect').each(function() {
    if ($(this).siblings('.multiselect-wrap').length) {
      return;
    }
    $(this).multiselect({
      allSelectedText: 'All subjects',
      nonSelectedText: 'All subjects',
      numberDisplayed: 3,
      allowClear: true,
      buttonContainer: '<div class="multiselect-wrap"/>',
      buttonClass: $(this).data('button-class')
    });
  });
});
